












































import { Component, Prop, Vue } from "vue-property-decorator";
import ImageComponent from "@/components/global/ImageComponent.vue";
import EntryUtils from "@/common/utilities/EntryUtils";
import _ from "lodash";
import StringUtils from "@/common/utilities/StringUtils";
import UserListDataContext from "@/dataContexts/UserListDataContext";
import Notification from "@/common/Notification";
import TranslationUtils from "@/common/utilities/TranslationUtils";
import { UserSessionManager } from "kogitte";
import { Anime, EntryTitle, Tag, Description, UserListEntry } from "@sokkuri/common";

@Component({
    components: {
        ImageComponent
    }
})
export default class AnimePanelComponent extends Vue {
    @Prop({ required: true }) private anime!: Anime;
    @Prop() private userListEntry: UserListEntry;

    private addedToList = false;

    private userListDataContext = new UserListDataContext();
    private userSessionManager = new UserSessionManager();

    private getPrimaryTitle(titles: EntryTitle[]) {
        return EntryUtils.getTitle(titles).title;
    }

    private getPrimaryDescription(descriptions: Description[]) {
        const description = EntryUtils.getDescription(descriptions);

        if (description.content.length > 100) {
            return `${StringUtils.shorten(description.content, 250)} ...`;
        }

        return description.content;
    }

    private getTagsToDisplay(tags: Tag[]) {
        return _.take(tags, 4);
    }

    private addToList() {
        this.userListDataContext.addAnime(this.anime.id).then(x => {
            if (x.successfully) {
                this.addedToList = true;
                Notification.addSuccess(TranslationUtils.translate("notification.savedSuccessfully")).show();
            }
        });
    }

    private translate(key: string) {
        return TranslationUtils.translate(key);
    }
}
