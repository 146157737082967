






























































import { Component, Vue } from "vue-property-decorator";
import SeasonDataContext from "@/dataContexts/SeasonDataContext";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import ImageComponent from "@/components/global/ImageComponent.vue";
import AnimePanelComponent from "@/components/global/AnimePanelComponent.vue";
import { UserSessionManager } from "kogitte";
import UserListDataContext from "@/dataContexts/UserListDataContext";
import { SeasonInfo, Anime, Constants, UserListEntry } from "@sokkuri/common";
import AnimeDataContext from "@/dataContexts/AnimeDataContext";

@Component({
    components: {
        SpinnerComponent,
        ImageComponent,
        AnimePanelComponent
    }
})
export default class SeasonOverviewView extends Vue {
    private loading = false;
    private initState = true;

    private seasons: SeasonInfo[] = [];
    private currentSeason: SeasonInfo = new SeasonInfo();
    private series: Anime[] = [];
    private movies: Anime[] = [];

    private userListAnimes: UserListEntry[] = [];

    private seasonDataContext = new SeasonDataContext();
    private animeDataContext = new AnimeDataContext();
    private userListDataContext = new UserListDataContext();

    created() {
        this.loading = true;

        this.seasonDataContext.getSeasonInfo().then(x => {
            if (x.successfully && x.data) {
                this.seasons = x.data;

                const currentSeason = this.seasons.find(y => y.current);

                if (currentSeason) {
                    this.currentSeason = currentSeason;
                }

                this.loadSeasonAnimes(this.currentSeason).then(() => {
                    this.loading = false;
                });
            }
        });
    }

    private async changeSeason(season: SeasonInfo) {
        this.currentSeason = season;
        this.clearAnimes();
        this.loading = true;

        await this.loadSeasonAnimes(season);

        this.loading = false;
    }

    private clearAnimes() {
        this.series = [];
        this.movies = [];
    }

    private async loadSeasonAnimes(season: SeasonInfo) {
        return this.animeDataContext.getAnimesBySeason(season.season, season.year).then(x => {
            if (x.successfully && x.data) {
                this.series = x.data.filter(y => y.type == Constants.AnimeTypes.Series);
                this.movies = x.data.filter(y => y.type == Constants.AnimeTypes.Movie);

                if (new UserSessionManager().sessionExists()) {
                    this.userListDataContext.getAnimeEntriesBySeason(season.season, season.year).then(y => {
                        if (y.successfully && y.data) {
                            this.userListAnimes = y.data;
                        }
                    });
                }

                this.initState = false;
            }
        });
    }

    private getAnimeUserListEntry(animeId: number) {
        return this.userListAnimes.find(x => x.anime.id == animeId);
    }
}
